import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import vuetify from './plugins/vuetify'
import store from '@/store/store'
import VueTheMask from 'vue-the-mask'
import {vMaska} from "maska/vue";

Vue.config.productionTip = false
Vue.use(VueTheMask);
Vue.directive('maska', vMaska)


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
